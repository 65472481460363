import axios from "axios";

import { getAuthToken } from "../auth";
import { getDefaultOrg } from "../org";

export const API_FQDN = process.env.REACT_APP_API_FQDN || "localhost:5100";

export const API_ENDPOINT =
  process.env.NODE_ENV === "production"
    ? `https://${API_FQDN}`
    : `http://${API_FQDN}`;

const ApiService = axios.create({
  baseURL: API_ENDPOINT,
});

ApiService.interceptors.request.use(
  function (config) {
    const token = getAuthToken();
    const organizationId = getDefaultOrg();
    if (token) config.headers["Authorization"] = "Bearer " + token;
    config.headers["Accept"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = API_FQDN;
    config.headers["X-Organization-ID"] = organizationId;
    return config;
  },
  function (error) {
    console.error(error);
    return Promise.reject(error);
  }
);

ApiService.defaults.timeout = 120000;

export default ApiService;
