import React from "react";
import {
  extractClaims,
  getAuthToken,
  removeAuthToken,
  setAuthToken,
  validToken,
} from "../auth";
import ApiService from "../xhr_libs";
import { getDefaultOrg, setDefaultOrg, setDefaultOrgIfNotExists } from "../org";

const initialState = Object.freeze({
  id: null,
  email: "",
  role: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  user_organizations: [],
  isOnline: false,
  selectedOrg: -1,
});

export const ORG_KEY = "defaultOrg";
export const PROFILE_KEY = "profile";

export const AppContext = React.createContext({ initialState });

export class AppProvider extends React.Component {
  constructor(props) {
    super(props);
    const isOnline = window.navigator.onLine;
    const claims = extractClaims(getAuthToken());
    const selectedOrg = getDefaultOrg();

    this.state =
      Object.keys(claims).length > 0
        ? { ...initialState, ...claims, isOnline, selectedOrg }
        : { ...initialState, isOnline, selectedOrg };
  }

  componentDidMount() {
    window.addEventListener("online", this.setOnline);
    window.addEventListener("offline", this.setOffline);
    this.fetchProfile();
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.setOnline);
    window.removeEventListener("offline", this.setOffline);
  }

  changeOrganization = (orgId) => {
    setDefaultOrg(orgId);
    this.setState({ selectedOrg: orgId });
    this.setRole(this.state.user_organizations);
  };

  fetchProfile = () => {
    if (!validToken()) return;
    ApiService.get("/profile")
      .then((res) => {
        this.setUser(res.data);
        this.setRole(res.data.user_organizations);
      })
      .catch((e) => {
        // Load from localStorage if available
        const cachedUserData = JSON.parse(localStorage.getItem(PROFILE_KEY));
        if (cachedUserData) this.setUser(cachedUserData);

        // If we failed here then we must be offline
        if (e && e.code === "ERR_NETWORK") this.setOffline();
      });
  };

  logoutUser = (_) => {
    removeAuthToken();
    this.setState(initialState);
  };

  setOnline = () => {
    console.info("Back online");
    this.setState({ isOnline: true });
  };

  setOffline = () => {
    console.warn("Going offline");
    this.setState({ isOnline: false });
  };

  setToken = (newToken) => {
    setAuthToken(newToken);
    const claims = extractClaims(getAuthToken());
    this.setState({ user: { ...claims } }, () => console.debug("User Updated"));
  };

  setUser = (userData, callback) => {
    callback = callback || function () {};
    // Also store to localStorage for offline
    localStorage.setItem(PROFILE_KEY, JSON.stringify(userData));
    this.setState({ ...userData }, (e) => callback(e));
  };

  setRole = (userOrganizations) => {
    const role = userOrganizations.find(
      (org) => org.organization_id === this.state.selectedOrg
    )?.role;
    if (role) this.setState({ role });
  };
  render() {
    const { setToken, setUser, logoutUser, changeOrganization } = this;
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          setToken,
          setUser,
          logoutUser,
          changeOrganization,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export const AppConsumer = AppContext.Consumer;
