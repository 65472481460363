export const DEFAULT_ORG_KEY = "default_org_id";

export function getDefaultOrg() {
  const storageValue = localStorage.getItem(DEFAULT_ORG_KEY);
  if (storageValue) return parseInt(storageValue);
  return -1;
}

export function setDefaultOrg(newOrganizationId) {
  console.info("Setting Default Org", newOrganizationId);
  localStorage.setItem(DEFAULT_ORG_KEY, newOrganizationId.toString());
}

export function setDefaultOrgIfNotExists(newOrganizationId) {
  const storageValue = getDefaultOrg();
  if (storageValue === null) setDefaultOrg(newOrganizationId);
  if (storageValue < 0) setDefaultOrg(newOrganizationId);
}
