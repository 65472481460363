import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AppRoute from "../../components/AppRoute";
import Trends from "./Trends";

const AdminLayout = lazy(() => import("./index"));
const Building = lazy(() => import("./Buildings"));
const Community = lazy(() => import("./Community"));
const FieldInspectionReports = lazy(() => import("./FieldInspectionReports"));
const InspectionSync = lazy(() => import("./InspectionSync"));
const Issue = lazy(() => import("./Issues"));
const Model = lazy(() => import("./Model"));
const User = lazy(() => import("./Users"));

const AdminRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<AppRoute component={AdminLayout} authenticated />}
      >
        <Route
          path="/issues"
          element={
            <AppRoute
              component={Issue}
              roles={[`admin`, `owner`]}
              authenticated
              navigation={false}
            />
          }
        />
        <Route
          path="/users"
          element={
            <AppRoute
              component={User}
              roles={[`admin`, `owner`]}
              authenticated
              navigation={false}
            />
          }
        />
        <Route
          path="/buildings"
          element={
            <AppRoute
              component={Building}
              roles={[`admin`, `owner`]}
              authenticated
              navigation={false}
            />
          }
        />
        <Route
          path="/communities"
          element={
            <AppRoute
              component={Community}
              roles={[`admin`, `owner`]}
              authenticated
              navigation={false}
            />
          }
        />
        <Route
          path="/models"
          element={
            <AppRoute
              component={Model}
              navigation={false}
              roles={[`admin`, `owner`]}
              authenticated
            />
          }
        />
        <Route
          path="/field_inspection_reports"
          element={
            <AppRoute
              component={FieldInspectionReports}
              roles={[`admin`, `owner`]}
              authenticated
              navigation={false}
            />
          }
        />
        <Route
          path="/trends"
          element={
            <AppRoute
              component={Trends}
              roles={[`admin`, `owner`]}
              authenticated
              navigation={false}
            />
          }
        />
        <Route
          path="/ekotrope/inspection_sync"
          element={
            <AppRoute
              component={InspectionSync}
              roles={[`admin`, `owner`]}
              authenticated
              navigation={false}
            />
          }
        />

        {/* Redirect to default admin page */}
        <Route
          index
          element={<Navigate replace to="field_inspection_reports" />}
        />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;
