import React from "react"
import { Navbar, NavDropdown, Nav } from "react-bootstrap"

import OfflineBanner from "./OfflineBanner"
import { AppConsumer } from "../contexts/app-context"

class Navigation extends React.Component {
  render() {
    return (
      <AppConsumer>
        {({
          isOnline,
          user_organizations,
          selectedOrg,
          changeOrganization
        }) => {
          const selectedOrgName = user_organizations.find(
            (org) => org.organization_id === selectedOrg
          )?.organization_name
          const marginTop = isOnline ? "0" : "2em"

          return (
            <React.Fragment>
              <OfflineBanner isOnline={isOnline} />
              <Navbar
                collapseOnSelect
                expand="lg"
                data-bs-theme="dark"
                className={`px-3`}
                style={{ marginTop, backgroundColor: `#343a40` }}
              >
                <Navbar.Brand href="/">
                  <img
                    style={{ marginBottom: "1em", height: "2em" }}
                    alt="Home"
                    src="/logo-full.svg"
                  />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link href="/field_inspection_report/all">
                      My Reports
                    </Nav.Link>
                    <Nav.Link href="/field_inspection_report">
                      Create Report
                    </Nav.Link>
                  </Nav>
                  <Nav>
                    <NavDropdown
                      title={selectedOrgName || "Select Organization"}
                      id="org-dropdown"
                    >
                      {user_organizations.map((org) => (
                        <NavDropdown.Item
                          key={org.organization_id}
                          onClick={() =>
                            changeOrganization(org.organization_id)
                          }
                        >
                          {org.organization_name}
                          {org.organization_id ===
                          parseInt(selectedOrg)
                            ? " (Current)"
                            : ""}
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                    <NavDropdown
                      title="Settings"
                      id="collasible-nav-dropdown"
                    >
                      <NavDropdown.Item href="/profile">
                        Profile
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/logout">
                        Logout
                      </NavDropdown.Item>

                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/admin">
                        Admin
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/about">
                        About
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </React.Fragment>
          )
        }}
      </AppConsumer>
    )
  }
}

export default Navigation
