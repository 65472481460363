import React from "react";
import { Navigate } from "react-router-dom";
import { AppConsumer } from "../contexts/app-context";
import { validToken, removeAuthToken } from "../auth";
import Navigation from "./Navigation";

const PrivateRoute = ({ component: Component, authenticated, roles, navigation }) => {
  const nav = navigation !== false;
  return (
    <AppConsumer>
      {({ ...appState }) => {
        const passthruRoute = (
          <React.Fragment>
            {nav && <Navigation {...appState} />}

            <Component {...appState} />
          </React.Fragment>
        );
        if (!authenticated) {
          return passthruRoute;
        }
        const valid = validToken();
        if (!valid) {
          removeAuthToken();
          return <Navigate to="/login" replace />;
        }
        if (appState.role && roles && !roles.includes(appState.role)) {
          return <Navigate to="/" replace />;
        }

        return passthruRoute;
      }}
    </AppConsumer>
  );
};

export default PrivateRoute;
